<template>
  <v-data-table
    :headers="headers"
    :items="payments"
    :items-per-page="10"
    class="elevation-1"
    :loading="isLoading"
    loading-text="Loading... Please wait"
  >
    <template v-slot:item.status="{ item }">
      <v-chip color="primary" outlined>
        {{ item.status }}
      </v-chip>
    </template>
    <template v-slot:item.paymentFile="{ item }">
      <v-btn v-if="item.paymentFile" icon @click="download(item)">
        <v-icon dark> mdi-cloud-download</v-icon>
      </v-btn>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon color="primary">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(option, i) in rowItemActions"
            :key="i"
            @click="option.action(item)"
          >
            <v-list-item-title>{{ option.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'PaymentsTable',

  props: {
    courseId: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    headers: [
      {
        text: 'Invoice No.',
        align: 'start',
        sortable: true,
        value: 'invoiceNumber',
      },
      {
        text: 'Amount Paid',
        align: 'start',
        sortable: false,
        value: 'invoiceAmount',
      },
      { text: 'Status', value: 'status' },
      { text: 'Payment File', value: 'paymentFile' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
  }),

  created() {
    this.bindPaymentsRef();
  },

  computed: {
    ...mapState('payments', ['isLoading', 'payments']),
    ...mapGetters('users', ['hashUsers']),
    ...mapGetters('auth', ['isAdmin']),
    rowItemActions() {
      return this.isAdmin
        ? [
            {
              label: 'Edit',
              action: (payment) => this.$emit('onEdit', 'payments', payment.id),
            },
            {
              label: 'Delete',
              action: (payment) => this.handleDeletePayment(payment.id),
            },
          ]
        : [];
    },
  },

  methods: {
    ...mapActions('payments', [
      'bindPaymentsRef',
      'deletePayment',
      'downloadPayment',
    ]),
    handleDeletePayment(id) {
      this.deletePayment(id);
    },
    async download(payment) {
      const url = await this.downloadPayment({
        courseId: this.courseId,
        fileName: payment.paymentFile,
      });
      //TODO: Download using temporary a tag and clicking on it
      window.open(url, '_blank').focus();
    },
  },
};
</script>
