var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.payments,"items-per-page":10,"loading":_vm.isLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.paymentFile",fn:function(ref){
var item = ref.item;
return [(item.paymentFile)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.download(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-cloud-download")])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.rowItemActions),function(option,i){return _c('v-list-item',{key:i,on:{"click":function($event){return option.action(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(option.label))])],1)}),1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }